import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';
import { useIntl } from 'react-intl';
import css from './ListingPage.module.css';
import { string } from 'prop-types';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextAttributes = ({ publicData, metadata }) => {
  const intl = useIntl();

  const isPackagingListing = metadata?.isPackaging;
  const capitalizedStorageType = publicData.storageType
    ? publicData.storageType.charAt(0).toUpperCase() + publicData.storageType.slice(1)
    : ''

    const capitalise = (string) => {
      if (!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const calculateAge = (birthDate) => {
      const birth = new Date(birthDate);
      const today = new Date();

      let years = today.getFullYear() - birth.getFullYear();
      let months = today.getMonth() - birth.getMonth();

      if (months < 0 || (months === 0 && today.getDate() < birth.getDate())) {
        years--;
        months += 12;
      }

      if (today.getDate() < birth.getDate()) {
        months--;
      }

      return `${years} Years ${months} Months`;
    };

  const renderCattleInfo = () => (
    <>
      {publicData.weight && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.weightLabel' })}
          text={`${publicData.weight} kg`}
        />
      )}
      {publicData.yearOfBirth && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.age' })}
          text={calculateAge(publicData.yearOfBirth)}
        />
      )}
      {publicData.subspecies && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.subspeciesLabel' })}
          text={publicData.subspecies}
        />
      )}
      {publicData.cattleUsedFor && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.cattleUsedForLabel' })}
          text={intl.formatMessage({ id: `EditListingPricingAndStockForm.${publicData.cattleUsedFor}` })}
        />
      )}
      {publicData.litterAndSire && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.cattleLitterAndSireUsedForLabel' })}
          text={publicData.litterAndSire}
        />
      )}
      {publicData.sire && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.cattleSireLabel' })}
          text={publicData.sire}
        />
      )}
    </>
  );

  const renderDogInfo = () => (
    <>
      {publicData.clubRegisteredName && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.clubRegisteredNameLabel' })}
          text={capitalise(publicData.clubRegisteredName)}
        />
      )}
      {publicData.clubName && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.clubNameLabel' })}
          text={capitalise(publicData.clubName)}
        />
      )}
      {publicData.yearOfBirth && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.age' })}
          text={calculateAge(publicData.yearOfBirth)}
        />
      )}
      {publicData.colour && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.colourLabel' })}
          text={capitalise(publicData.colour)}
        />
      )}
      {publicData.coatType && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.coatTypeLabel' })}
          text={capitalise(publicData.coatType)}
        />
      )}
      {publicData.weight && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.weightLabel' })}
          text={`${publicData.weight} kg`}
        />
      )}
      {publicData.litter && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.litterLabel' })}
          text={publicData.litter}
        />
      )}
      {publicData.sire && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.sireLabel' })}
          text={publicData.sire}
        />
      )}
      {publicData.geneticTesting && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.geneticTestingLabel' })}
          text={capitalise(publicData.geneticTesting)}
        />
      )}
      {publicData.pedigreeCertification && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.pedigreeCertificationLabel' })}
          text={capitalise(publicData.pedigreeCertification)}
        />
      )}
      {publicData.healthDocuments && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.healthDocumentsLabel' })}
          text={capitalise(publicData.healthDocuments)}
        />
      )}
    </>
  );

  const renderDefaultInfo = () => (
    <>
      {publicData.colour && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.colourLabel' })}
          text={publicData.colour}
        />
      )}
      {publicData.weight && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.weightLabel' })}
          text={`${publicData.weight} kg`}
        />
      )}
      {publicData.height && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.heightLabel' })}
          text={`${publicData.height} cm`}
        />
      )}
      {publicData.thoroughbreed && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.thoroughbreedLabel' })}
          text={`${publicData.thoroughbreed} %`}
        />
      )}
    </>
  )

  const renderHorseInfo = () => (
    <>
      {publicData.height && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.heightLabel' })}
          text={`${publicData.height} cm`}
        />
      )}
      {publicData.weight && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.weightLabel' })}
          text={`${publicData.weight} kg`}
        />
      )}
      {publicData.yearOfBirth && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.age' })}
          text={calculateAge(publicData.yearOfBirth)}
        />
      )}
      {publicData.discipline && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.disciplineLabel' })}
          text={intl.formatMessage({ id: `EditListingPricingAndStockForm.${publicData.discipline}` })}
        />
      )}
      <div>
        <Heading as="h2" rootClassName={css.sectionCustomHeading}>
          {intl.formatMessage({ id: 'ListingPage.registrationBodyLabel' })}
        </Heading>
        <div className={css.sectionTextAttributesContainer}>
          {publicData.bloodLine && (
            <DetailItem
              label={intl.formatMessage({ id: 'ListingPage.bloodLineLabel' })}
              text={publicData.bloodLine}
            />
          )}
          {publicData.sire && (
            <DetailItem
              label={intl.formatMessage({ id: 'ListingPage.sireLabel' })}
              text={publicData.sire}
            />
          )}
          {publicData.dam && (
            <DetailItem
              label={intl.formatMessage({ id: 'ListingPage.damLabel' })}
              text={publicData.dam}
            />
          )}
          {publicData.grandparents && (
            <DetailItem
              label={intl.formatMessage({ id: 'ListingPage.grandparentsLabel' })}
              text={publicData.grandparents}
            />
          )}
        </div>
      </div>
    </>
  )

  const renderPigInfo = () => (
    <>
      {publicData.weight && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.weightLabel' })}
          text={`${publicData.weight} kg`}
        />
      )}
      {publicData.yearOfBirth && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.age' })}
          text={calculateAge(publicData.yearOfBirth)}
        />
      )}
      {publicData.litterSize && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.litterSizeLabel' })}
          text={publicData.litterSize}
        />
      )}
      {publicData.sire && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.sireLabel' })}
          text={publicData.sire}
        />
      )}
    </>
  )

  const renderSheepInfo = () => {
    return (
      <>
        {publicData.weight && (
          <DetailItem
            label={intl.formatMessage({ id: 'ListingPage.weightLabel' })}
            text={`${publicData.weight} kg`}
          />
        )}
        {publicData.colour && (
          <DetailItem
            label={intl.formatMessage({ id: 'ListingPage.colourLabel' })}
            text={publicData.colour}
          />
          )}
        {publicData.yearOfBirth && (
          <DetailItem
            label={intl.formatMessage({ id: 'ListingPage.age' })}
            text={calculateAge(publicData.yearOfBirth)}
          />
        )}
        {publicData.litterAndSire && (
          <DetailItem
            label={intl.formatMessage({ id: 'ListingPage.seirLabel' })}
            text={publicData.litterAndSire}
          />
          )}
        </>
    )
  }

  const renderCurrentContent = () => {
    switch (publicData.listingType) {
      case 'cattle':
        return renderCattleInfo();
      case 'dog':
        return renderDogInfo();
      case 'horse':
        return renderHorseInfo();
      case 'pig':
        return renderPigInfo();
      case 'sheep':
        return renderSheepInfo();
      default:
        return renderDefaultInfo();
    }
  }

  if (isPackagingListing) return null;

  return (
    <div className={css.sectionTextAttributesContainer}>
      {publicData.origin && (
        <DetailItem
          label={intl.formatMessage({ id: 'ListingPage.originLabel' })}
          text={publicData.origin}
        />
      )}
      {renderCurrentContent()}
    </div>
  );
};

const DetailItem = ({ label, text }) => {
  const intl = useIntl();
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    breakChars: '/',
  });

  return (
    <div className={css.sectionText}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        {label}
      </Heading>
      <p className={`${css.text} ${css.whiteText}`}>{content}</p>
    </div>
  );
};

export default SectionTextAttributes;
