import React from 'react';

import css
  from '../../containers/EditListingPage/EditListingWizard/EditListingPhotosPanel/EditListingPhotosPanel.module.css';
import ListingImage
  from '../../containers/EditListingPage/EditListingWizard/EditListingPhotosPanel/ListingImage';

const CertificateModalContent = ({ imageId, imageVariants }) => {
  const imageData = {
    attributes : {
      variants: imageVariants,
    },
    id: {
      uuid: imageId,
      _sdkType: "UUID",
    },
    type: "image",
  };

  return (
    <div className={css.certificateImageWrapper}>
      <ListingImage
        image={imageData}
        key={imageData?.id?.uuid}
        savedImageAltText="Saved image"
        onRemoveImage={() => null}
        aspectWidth={100}
        aspectHeight={100}
        showRemoveButton={false}
      />
    </div>
  );
}

export default CertificateModalContent;
